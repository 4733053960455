import React, { useEffect, useState, useContext, useRef } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import Layout from "../components/Layout"
import Loader from "../components/ui/Loader"
import { useLocation } from "@reach/router"
import { BrinkContext } from "../components/context/BrinkContext"
import { subscribeToNewsletter } from "../components/context/utils/klaviyoEvents"

const Container = styled.div`
  max-width: 70rem;
  margin: 14.2rem auto 0;
  padding: 0;
`

const ErrorMessage = styled.div`
  color: ${(p) => p.theme.colors.errors};
  border: 0.1rem solid ${(p) => p.theme.colors.errors};
  padding: 1.5rem 2rem;
  letter-spacing: 0;
  margin-bottom: 2rem;
  width: 100%;
  line-height: 1.8rem;
`

const KCO = styled.div`
  min-height: 40rem;
`

export default ({ pageContext }) => {
  const { t } = useTranslation("translations")
  const { closeCart, getKlarnaOrder, currentStore } = useContext(BrinkContext)
  const [isLoading, setIsLoading] = useState(true)
  const [displayError, setDisplayError] = useState(false)
  const [klarnaSnippet, setKlarnaSnippet] = useState(null)
  const useMountEffect = (fun) => useEffect(fun, [])
  const location = useLocation()
  const klarnaOrderId = new URLSearchParams(location.search).get("klarnaOrderId")
  const kcoRef = useRef(null)

  if (!klarnaOrderId) return null

  useMountEffect(() => {
    if (klarnaSnippet) return

    const loadConfirmation = async () => {
      const {
        html_snippet: klarnaSnippet,
        billing_address: { email },
        merchant_requested: { additional_checkbox: newsletterChecked } = {}
      } = await getKlarnaOrder(klarnaOrderId)

      setKlarnaSnippet(klarnaSnippet)
      newsletterChecked && subscribeToNewsletter(currentStore.countryCode, email)
    }

    loadConfirmation()
      .catch(() => setDisplayError(true))
      .finally(() => closeCart(true))
  })

  useEffect(() => {
    if (!klarnaSnippet) return

    const scriptsTags = kcoRef.current.getElementsByTagName("script")
    // This is necessary otherwise the scripts tags are not going to be evaluated
    for (let i = 0; i < scriptsTags.length; i++) {
      const parentNode = scriptsTags[i].parentNode
      const newScriptTag = document.createElement("script")
      newScriptTag.type = "text/javascript"
      newScriptTag.text = scriptsTags[i].text
      parentNode.removeChild(scriptsTags[i])
      parentNode.appendChild(newScriptTag)
    }

    setIsLoading(false)
  }, [klarnaSnippet])

  return (
    <Layout meta={{ title: "Success" }} invertedHeader hideCountrySelector pageContext={pageContext}>
      <Container>
        {isLoading && <Loader isLoading={isLoading} />}
        {displayError && (
          <ErrorMessage>
            {t("Something went wrong with. Please try another option or contact our support.")}
          </ErrorMessage>
        )}
        <KCO ref={kcoRef} dangerouslySetInnerHTML={{ __html: klarnaSnippet }} />
      </Container>
    </Layout>
  )
}
